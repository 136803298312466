import React, { useState, useEffect } from 'react';

import { Modal, Row, Col, Button } from 'react-bootstrap';

import axios from 'axios';

const CustomModal = props => {    
    const [showCustom, setShowCustom] = useState(false);
    const [customData, setCustomData] = useState();

    const handleShowCustom = () => {
        setShowCustom(true);
        fetchCustom()
    }

    const handleCloseCustom = () => {
        setShowCustom(false);
        props.onClose();
    }
    
    const fetchCustom = async () => {
        try {
            const responseData = await axios.get('https://gorillamediallc.com/betabbserver/stored/data/custom_list.json');
            const filterData = responseData.data.filter((custom) => custom.type === props.type);
            const resultCustom = filterData.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, 1);

            setCustomData(resultCustom[0])
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if(props.display_custom === true && props.type) handleShowCustom()
    }, [props]);

    // counter
    const [countNumber, setCountNumber] = useState(0)
    const [affPercent, setAffPercent] = useState(8)

    const clickedAd = () => {
        setCountNumber(countNumber + 1)
    }

    useEffect(() => {
        if(countNumber >= 10) setCountNumber(0)
    }, [countNumber]);

    let adlink;
    if(customData?.split === true) {

        if(affPercent > countNumber){
            adlink = customData?.redirection_aff;
        } else {
            adlink = customData?.redirection;
        } 

    } else {
        adlink = customData?.redirection;
    }

    const aff_sub = localStorage.getItem('transaction_id')
    const aff_sub1 = localStorage.getItem('aff_sub1')
    const aff_sub2 = localStorage.getItem('aff_sub2')
    const aff_sub3 = localStorage.getItem('aff_sub3')
    const aff_sub4 = localStorage.getItem('aff_sub4')
    const aff_sub5 = localStorage.getItem('aff_sub5')

    const link = `?sub1=${aff_sub1}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub4=${aff_sub4}&sub5=${aff_sub5}&transaction_id=${aff_sub}`;

    
    return (
        <Modal show={showCustom} onHide={handleCloseCustom} className="cusxm">
        <Modal.Body className="text-center">
        {customData && (
            <a target="_blank" href={adlink + link} onClick={() => clickedAd()} rel="noreferrer">
                <img src={customData.image} className="img-fluid" alt={customData.id}  />
            </a>
        )}
        </Modal.Body>
        </Modal>
    )
};

export default CustomModal;