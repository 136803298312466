import React from 'react';

import { Container, Button } from 'react-bootstrap';

const PrivacyPolicy = () => {
    return (
        <>
        <div className="infopage-body">
        <Container>
            <a href="index.html"> <Button variant="dark" className="m-4"> <i className="fa-solid fa-arrow-left-long"></i> Back to Home </Button> </a>	
	        <div className="additional-page addbordercontent customcontent-addpages">
            <h1>Privacy Policy</h1>
            <p>It is BangBase’s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to (hereinafter, "us", "we", or "our"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.
            This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.</p>
        
            <h2>WEBSITE VISITORS</h2>
            <p>Like most website operators, we collect non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. BangBase’s purpose in collecting non-personally identifying information is to better understand how BangBase’s visitors use its website. From time to time, BangBase.app may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.
            BangBase.app also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on https://www.bangbase.app blog posts. BangBase only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>

            <h2>GATHERING OF PERSONALLY-IDENTIFYING INFORMATION</h2>
            <p>Certain visitors to BangBase’s websites choose to interact with bangbase.app in ways that require bangbase.app to gather personally-identifying information. The amount and type of information that bangbase.app gathers depend on the nature of the interaction. For example, we ask visitors who sign up for a blog at https://www.bangbase.appto provide a username and email address.</p>

            <h2>SECURITY</h2>
            <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
        
            <h2>ADVERTISEMENT</h2>
            <p>Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by bangbase.app and does not cover the use of cookies by any advertisers.</p>
        
            <h2>LINKS TO EXTERNAL SITES</h2>
            <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and the terms and conditions of every site you visit.We have no control over and assume no responsibility for the content, privacy policies, or practices of any third party sites, products, or services.</p>
        
            <h2>PROTECTION OF CERTAIN PERSONALLY-IDENTIFYING INFORMATION</h2>
            <p>bangbase.app discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors, and affiliated organizations that (i) need to know that information in order to process it on BangBase’s behalf or to provide services available at BangBase’s website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using BangBase’s website, you consent to the transfer of such information to them. bangbase.app will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors, and affiliated organizations, as described above, bangbase.app discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental requests, or when bangbase.app believes in good faith that disclosure is reasonably necessary to protect the property or rights of bangbase.app, third parties or the public at large. We do not share personal data with other companies, organizations and individuals unless one of the following circumstances applies:</p>
            <ul>
                <li>With your consent. We will share personal data with other companies, organizations or individuals when we have your consent to do so. We may communicate your personal data to the third parties for their own marketing activities, to keep you up-to-date with the latest news and offers from our partners.</li>
                <li>Other users. Any data you include on your Profile and any action you take on our Service may be seen by other users.</li>
                <li>When you update or add photo (which you can change) is to share it publicly.</li>
                <li>When you like, add to favorite or visit other user Profile, that person will see it.</li>
                <li>We let senders know when you act on their letters or chat message.</li>
            </ul>
            <p>If you are a registered user of https://www.bangbase.appand have supplied your email address, bangbase.app may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with bangbase.app and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. bangbase.app takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.</p>
        
            <h2>AGGREGATED STATISTICS</h2>
            <p>bangbase.app may collect statistics about the behavior of visitors to its website. bangbase.app may display this information publicly or provide it to others. However, bangbase.app does not disclose your personally-identifying information.</p>
        
            <h2>AFFILIATE DISCLOSURE</h2>
            <p>This site uses affiliate links and does earn a commission from certain links. This does not affect your purchases or the price you may pay.</p>

            <h2>COOKIES</h2>
            <p>To enrich and perfect your online experience, bangbase.app uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.
            A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. bangbase.app uses cookies to help bangbase.app identify and track visitors, their usage of http://www.bangbase.app, and their website access preferences. bangbase.app visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using BangBase’s websites, with the drawback that certain features of BangBase’s websites may not function properly without the aid of cookies.
            By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to BangBase's use of cookies.</p>

            <h2>E-COMMERCE</h2>
            <p>Those who engage in transactions with bangbase.app – by purchasing BangBase's services or products are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, bangbase.app collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s interaction with bangbase.app. bangbase.app does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.</p>
        
            <h2>BUSINESS TRANSFERS</h2>
            <p>If bangbase.app, or substantially all of its assets, were acquired, or in the unlikely event that bangbase.app goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur and that any acquirer of bangbase.app may continue to use your personal information as set forth in this policy.</p>
        
            <h2>PRIVACY POLICY CHANGES</h2>
            <p>Although most changes are likely to be minor, bangbase.app may change its Privacy Policy from time to time, and in BangBase’s sole discretion. bangbase.app encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
        
            <h2>YOUR CONSENT TO THIS AGREEMENT</h2>
            <p>By using The Company site/network, you consent to the collection and use of information by The Company site/network as specified above. We reserve the right to modify this Policy. If we decide to change our Privacy Policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.
            Please send any questions about the Company's Privacy Policy to support@bangbase.app
            Please note that your use of The Company's Web sites is governed by our Legal Notice.</p>

            <hr />

            <h1>DMCA</h1>
            <h2>DMCA Notice & Takedown Policy</h2>
            <p>NOTICE OF CLAIMED INFRINGEMENT</p>
            <p>If you believe that your work has been copied in a way that constitutes copyright infringement, please provide Our Designated Copyright Agent (identified below) with the following information:</p>

            <ul>
                <li>1.An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;</li>
                <li>2.Description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
                <li>3.Description of where the material that you claim is infringing is located on the SITE (preferably including specific url's associated with the material);</li>
                <li>Your address, telephone number, and email address;</li>
                <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and,</li>
                <li>A statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.</li>
            </ul>			
            </div>            
        </Container>
        </div>
        </>
    )
}

export default PrivacyPolicy;