import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { Badge } from 'react-bootstrap';

function StatsTable({ data, onCalculateProfitAndRevenue }) {
  const columns = [
    {
      Header: 'Info',
      accessor: 'columns[0].label',
      id: 'Info',
    },
    {
      Header: 'Gross Clicks',
      accessor: 'reporting.gross_click',
      id: 'Gross Clicks',
      sortMethod: (a, b) => a - b,
    },
    {
      Header: 'Profit Margin',
      accessor: (row) => {
        const revenue = row.reporting.revenue;
        const payout = row.reporting.payout;
        if (isNaN(revenue) || isNaN(payout) || revenue === 0) {
          return 0;
        }
        const profitMargin = ((revenue - payout) / revenue) * 100;
        if (isNaN(profitMargin) || !isFinite(profitMargin)) {
          return 0;
        }
        return profitMargin;
      },
      id: 'Profit Margin',
      sortMethod: (a, b) => a - b,
    },
    {
      Header: 'Throttle Rate',
      accessor: (row) => {
        const invalidCVScrub = row.reporting.invalid_cv_scrub;
        const totalCV = row.reporting.total_cv;
        if (isNaN(invalidCVScrub) || isNaN(totalCV) || totalCV === 0) {
          return 0;
        }
        const throttleRate = (invalidCVScrub / totalCV) * 100;
        if (isNaN(throttleRate) || !isFinite(throttleRate)) {
          return 0;
        }
        return throttleRate;
      },
      id: 'Throttle Rate',
      sortMethod: (a, b) => a - b,
    },
    {
      Header: 'CPL',
      accessor: (row) => {
        const payout = row.reporting.payout;
        const cv = row.reporting.cv;
        if (isNaN(payout) || isNaN(cv) || cv === 0) {
          return 0;
        }
        const cpl = payout / cv;
        if (isNaN(cpl) || !isFinite(cpl)) {
          return 0;
        }
        return cpl;
      },
      id: 'CPL',
      sortMethod: (a, b) => a - b,
    },
    {
      Header: 'RPL',
      accessor: (row) => {
        const revenue = row.reporting.revenue;
        const cv = row.reporting.cv;
        if (isNaN(revenue) || isNaN(cv) || cv === 0) {
          return 0;
        }
        const rpl = (revenue / cv).toFixed(3);
        if (isNaN(rpl) || !isFinite(rpl)) {
          return 0;
        }
        return rpl;
      },
      id: 'RPL',
      sortMethod: (a, b) => a - b,
    },
    {
      Header: 'CPC',
      accessor: (row) => {
        const payout = row.reporting.payout;
        const grossClick = row.reporting.gross_click;
        if (isNaN(payout) || isNaN(grossClick) || grossClick === 0) {
          return 0;
        }
        const cpc = (payout / grossClick).toFixed(3);
        if (isNaN(cpc) || !isFinite(cpc)) {
          return 0;
        }
        return cpc;
      },
      id: 'CPC',
      sortMethod: (a, b) => a - b,
    },
    {
      Header: 'EPC',
      accessor: (row) => {
        const revenue = row.reporting.revenue;
        const gross_click = row.reporting.gross_click;
        const epc = revenue && gross_click ? (revenue / gross_click).toFixed(3) : 0;
        return epc;
      },
      id: 'EPC',
      sortMethod: (a, b) => a - b,
    },
    {
      Header: 'Revenue',
      accessor: 'reporting.revenue',
      id: 'Revenue',
      sortMethod: (a, b) => a - b,
    },
    {
      Header: 'Quality Metric',
      accessor: (row) => (row.reporting.profit / row.reporting.revenue) * 100,
      id: 'Quality Metric',
      Cell: ({ value }) => {
        if (value < 10) {
          return <Badge bg="danger">Bad</Badge>;
        } else if (value >= 10 && value < 20) {
          return <Badge bg="warning">Average</Badge>;
        } else {
          return <Badge bg="success">Good</Badge>;
        }
      },
    },
  ];

  const [profit, setProfit] = useState(0);
  const [revenue, setRevenue] = useState(0);

  useEffect(() => {
    let calculatedProfit = 0;
    let calculatedRevenue = 0;
    data && data.forEach((row) => {
      calculatedProfit += row.reporting.profit;
      calculatedRevenue += row.reporting.revenue;
    });
    setProfit(calculatedProfit);
    setRevenue(calculatedRevenue);
    onCalculateProfitAndRevenue(calculatedProfit, calculatedRevenue);
  }, [data, onCalculateProfitAndRevenue]);

  const [pageSize, setPageSize] = useState(20);

  return (
    <>
      {data && (
        <>
        <ReactTable
        data={data}
        columns={columns}
        showPagination={true}
        defaultPageSize={pageSize}
        onPageChange={(pageIndex) => console.log('Page changed:', pageIndex)}
        onPageSizeChange={(newPageSize, pageIndex) => {
          setPageSize(newPageSize);
        }}
        sortable={true}
        defaultSorted={[{ id: 'Gross Clicks', desc: true }]}
        filterable={true}
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : true;
        }}
        />
        </>
      )}
    </>
   )
}
      
export default StatsTable;