import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { useHttpClient } from '../../shared/http-hook';

const DashboardLocal = () => {
    const { sendRequest } = useHttpClient();
    const [userInfo, setUserInfo] = useState();
    const[usersData, setUsersData] = useState();

    const [glat, setLat] = useState(0);
    const [glng, setLng] = useState(0);

    const getInfo = async () => {
        try {
            const response = await sendRequest('https://geolocation-db.com/json/');
            setUserInfo(response);
            setLat(response.latitude)
            setLng(response.longitude)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getInfo();
    }, []);

 
    const getUsersData = async () => {
        try {
            const response = await sendRequest('../data/users.json');
            const result = response.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, 6);
            
            setUsersData(result)
        } catch (err) {
            console.log(err);
        }
    }   

    useEffect(() => {
        getUsersData()
    }, [glat]);

    const MapMaker = ({ image, nick, maker_options }) => {
        return (
            <div style={{ top: maker_options.top + 'px', left: maker_options.left + 'px', right: maker_options.right + 'px' }} className="google-mapdot position-absolute"><Link to={`/dashboard/member/${nick}`}><img src={image} className="img-fluid mapavatar" style={{ height: "50px", width: "50px" , objectFit: 'cover'}} alt={image} /></Link></div>
        );
    };

    const options = {
        center: {
          lat: glat,
          lng: glng
        },
        zoom: 10
    };

    function createMapOptions() {
        return {
          gestureHandling: 'none',
          draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true
        }
    }
    

    return (
        <>
        {userInfo && (
            <>
            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
                defaultCenter={options.center}
                defaultZoom={options.zoom}
                options={createMapOptions}
                >
                    {usersData && usersData.map((item, index) => {    
                        const maker_options = {
                            top: Math.floor(Math.random() * 100),
                            left: Math.floor(Math.random() * 100) * index,
                            right: Math.floor(Math.random() * 100) * index,                        }                    
                        return (
                            <React.Fragment key={index}>
                            <MapMaker
                            image={item.image}
                            nick={item.nick}
                            maker_options={maker_options}
                            />
                            </React.Fragment>
                        )
                    })}
                </GoogleMapReact>
            </div>
            </>
        )}
        </>
    )
}

export default DashboardLocal;