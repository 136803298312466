import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import Preview from './Preview';
import PreviewImage from './PreviewImage';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="*" element={<App />} />
        <Route path="/preview/:id" element={<Preview />} />
        <Route path="/previewimage/:id" element={<PreviewImage />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
