import React, { useState, useEffect } from 'react';

import { Row, Col, Button } from 'react-bootstrap';

import { useHttpClient } from '../../shared/http-hook';
import LoadingSpinner from '../LoadingSpinner';
import {
    Player,
    DefaultUi,
    Scrim,
    Controls,
    ControlSpacer,
    MuteControl,
    PlaybackControl,
    ClickToPlay,
    Hls
  } from '@vime/react';
  import '@vime/core/themes/default.css';
const DashboardLiveCams = () => {
    const { isLoading, error, sendRequest } = useHttpClient();

    const [allModels, setAllModels] = useState([])
    const [displayModels, setDisplayModels] = useState([]);
    const [startPag, setStartPage] = useState(0);
    const [endPag, setEndPage] = useState(10);
    const [tags, setTags] = useState(["Anal", "Asian", "ASMR", "Athletic", "Bald", "BBW", "BDSM", "Best for Privates", "Big Ass", "Big Tits", "Blonde", "Blowjob", "Bondage", "Brunette", "Cam2Cam", "Colorful", "Cooking", "Corset", "Cosplay", "Cuckold", "Curvy", "Deepthroat", "Dildo or Vibrator", "Dirty Talk", "Domination", "Ebony", "Emo", "Fisting", "Foot Fetish", "Fuck Machine", "Goth", "Granny", "Group Sex", "Hairy Pussy", "Hardcore", "HD", "Indian", "Interactive Toys", "Interracial", "Jerk-off Instruction", "Kiiroo", "Latex", "Latina", "Leather", "Lesbian", "Lovense", "Masturbation", "Mature", "Medium", "MILF", "Mistress", "Mobile", "New Models", "Non Nude", "Office", "Outdoor", "Piercing", "Pregnant", "Redhead", "Role Play", "Sex Toys", "Shaven", "Skinny", "Slave", "Small Tits", "Spy on Shows", "Squirt", "Striptease", "Swingers", "Tattoos", "Teen 18+", "Video Games", "VR Cams", "White", "Yoga", "Young 22+", "69 Position"])

    const aff_sub2 = localStorage.getItem('aff_sub2')
    const aff_sub3 = localStorage.getItem('aff_sub3')

    const fetchStrip = async (tag) => {
        let strip;
        try {
            strip = await sendRequest(`https://go.xlrdr.com/api/models?limit=1000`)
            
        } catch (err) {
            error.log(err)
        }

        return strip;
    }

    useEffect(() => {
        Promise.all([fetchStrip()])
        .then(function (results) {
            setAllModels(results[0].models)     
            
            const splice = results[0].models.splice(startPag, endPag)

            setDisplayModels(splice)
        });
    }, [])

    const hlsConfig = {
        autoPlay: false,
    }

    const loadMore = async () => {
        setStartPage(startPag + 10)
        setEndPage(endPag + 10)

        const result = allModels?.splice(startPag, endPag)

        const updatedList = displayModels.concat(result);

        setDisplayModels(updatedList)
    }

    const [activeTagName, setActiveTagName] = useState()

    const updateTag = async (tag) => {

        try {
            const responseData = await sendRequest(`https://go.xlrdr.com/api/models?limit=1000&tag=girls,girls/${tag}`)
            
            const resModels = responseData.models;
            const result = resModels.sort(function() { 
                return .5 - Math.random() 
            });

            setAllModels(result)     
            
            const splice = result.splice(startPag, endPag)

            setDisplayModels(splice)

            setActiveTagName(tag)
        } catch (err) {
            console.log(err)
        } 
    }

    return (
        <>
             <div className="dashboard-right addbordercontent position-relative">

             <h3 className="db-title"><i className="fa-solid fa-video"></i> Live Cams </h3> <hr />

             <div className="overflow-auto">
             <p>Suggested tags:</p>

                <div className="wrapScroll">
                {
                    tags && tags.map((tg, index) =>{
                        let act;
                        activeTagName === tg ? act = "m-1 active" : act = "m-1"
                        
                        let convert_tag = tg.replace(' ', '-').toLowerCase();

                        return (
                            <React.Fragment key={`tg` + index}>
                                <div className="d-inline">
                                <Button variant="dark" className={act} onClick={() => updateTag(convert_tag)} size="sm">{tg}</Button>
                                </div>
                            </React.Fragment>
                        )
                    })
                }
                </div>
            </div>

            <hr />

            {isLoading && <LoadingSpinner asOverlay />}

             <Row className="m-0">
             {!isLoading && displayModels && displayModels.map((item, i) => {
                return (
                    <React.Fragment key={`cam` + i}>
                        <Col sm={6}>

                            <a href={`https://go.xlrdr.com?campaignId=bangbaselive&sourceId=${aff_sub2}&userId=f7b7333a5a3a2910a38064fd496bc6b4e821a3a41f3bf2c87d8feb79ff9a2d54&targetDomain=bangbase.live&onlineModels=${item.username}`} target="_blank">
                                <div className="liveblock-cam position-relative">
                                    
                                <div className="video-badge"> <i className="fa-solid fa-record-vinyl"></i> LIVE NOW </div>

                                <Player theme="dark" muted>
                                {/* ... */}
                                <Hls version="latest" config={hlsConfig} poster={item?.previewUrlThumbBig}>
                                    <source data-src={item?.stream?.url} type="application/x-mpegURL" />
                                </Hls>
                                {/* We turn off the controls that come with the default UI. */}
                                <DefaultUi noControls>
                                    <Scrim />

                                    <Controls fullWidth pin="topLeft">
                                    <ControlSpacer />
                                    <MuteControl />
                                    </Controls>

                                    <Controls pin="center">
                                    <PlaybackControl hideTooltip style={{ '--vm-control-scale': 1 }} />
                                    </Controls>

                                </DefaultUi>
                                </Player>
                                </div>
                            </a>

                        </Col>
                    </React.Fragment>
                )
             })}
             </Row>

             {!isLoading && allModels?.length > endPag + 20 && (
                <>
                    <div className="members-buttons-footer text-center">
                        <a href={`https://www.gcdates.com/3J67C/26S3GX3/`} target="_blank">
                        <Button variant="dark" className="mt-2 w-50" size="lg"> LOAD MORE </Button>    
                        </a>
                    </div>    
                </>
             )} 

             </div>
        </>
    )
}

export default DashboardLiveCams;