import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import axios from 'axios';

import { Row, Col, DropdownButton, ButtonGroup, Dropdown, Modal, Button, InputGroup, Form } from 'react-bootstrap'

import './widget.css'

const PreviewImage = () => {
    const id = useParams().id;

    const [post, setPost] = useState()

    const fetchFeed = async () => {
        try {
            const response = await axios.get(`https://gorillamediallc.com/betabbserver/api/getfeedpost/${id}`)
            if(response.data.feed) {
                setPost(response.data.feed)
            } else {
                window.location.href = '/'
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchFeed()
    }, []);

    // LINKS
    const copyLink = () => {
        const currentLink = window.location.origin+`/previewimage/${_id}`;

        navigator.clipboard.writeText(currentLink)
            .then(() => {
                alert('Link copied!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };


     // EMBED
    const [showEmbed, setShowEmbed] = useState(false);

    const handleCloseEmbed = () => setShowEmbed(false);
    const handleShowEmbed = () => setShowEmbed(true);

    const copyLinkInput = (type) => {
        
        let input;
        if(type === 'fixed') {
        input = document.getElementById('fixed-frame')
        } else if(type === 'responsive') {
        input = document.getElementById('responsive-frame')
        }

        navigator.clipboard.writeText(input.value)
            .then(() => {
                alert('Link copied!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <>
        <Modal show={showEmbed} onHide={handleCloseEmbed} className="custom_adsmodal">
        <Modal.Header closeButton>
        <Modal.Title>Embed</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4>Fixed frame</h4>
        <InputGroup className="mb-3">
            <Form.Control
            id='fixed-frame'
            value={`<iframe src='${window.location.origin+`/previewimage/${id}`}' frameborder='0' scrolling='no' allowfullscreen width='1080' height='1920'></iframe><p><a href='${window.location.origin+`/previewimage/${id}`}'>via BBGifs</a></p>`}
            />
            <Button variant="outline-secondary" id="button-addon-fixed" onClick={() => copyLinkInput('fixed')}>
            COPY
            </Button>
        </InputGroup>

        <h4>Responsive frame:</h4>
        <InputGroup className="mb-3">
            <Form.Control
            id='responsive-frame'
            value={`<div style='position:relative;'><iframe src='${window.location.origin+`/previewimage/${id}`}' frameBorder='0' scrolling='no' width='100%' height='1000' allowFullScreen></iframe></div><p><a href='${window.location.origin+`/previewimage/${id}`}'>via BBGifs</a></p>`}
            />
            <Button variant="outline-secondary" id="button-addon-responsive" onClick={() => copyLinkInput('responsive')}>
            COPY
            </Button>
        </InputGroup>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEmbed}>
            Close
        </Button>
        </Modal.Footer>
        </Modal>

        {post && (
            <>
            <Helmet>
                <meta name="description" content={post.description} />
                
                <meta property="og:description" content={post.description} />
                <meta property="og:type" content="video.other" />
                <meta property="og:url" content={window.location.href} />

                <meta name="twitter:card" content="player" />
                <meta name="twitter:description" content={post.description} />
                <meta name="twitter:player" content={window.location.href} />
                <meta name="twitter:player:width" content="640" />
                <meta name="twitter:player:height" content="360" />
            </Helmet>


            <div className='widget-body'>
            <Row>
                <Col xl={12} xs={12}>
                <div className='video-body-preview position-relative'>
                    <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ maxHeight: '40px' }} alt="small logo" className="widget-logo" />

                    <div id="video-container">
                        <img
                            src={`https://gorillamediallc.com/betabbserver/${post.video}`}
                            width='100%'
                            height='100%'
                        />
                    </div>
                    <div className='video-options-widget'>
                        <DropdownButton
                            as={ButtonGroup}
                            key='start'
                            id={`dropdown-button-drop-start`}
                            drop='start'
                            variant='secondary'
                            title={<span><i className="fa-solid fa-share-from-square"></i></span>} 
                            data-bs-theme="dark"
                            style={{ marginLeft: '-7px'}}
                        >
                            <Dropdown.Item eventKey="1" onClick={copyLink}>Copy Link</Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={handleShowEmbed}>Embed</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="3" href={`https://www.reddit.com/submit?title=${encodeURIComponent(post.description)}&url=${encodeURIComponent(window.location.href)}`} target='_blank'>Reddit</Dropdown.Item>
                            <Dropdown.Item eventKey="4" href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(post.description)}&url=${encodeURIComponent(window.location.href)}`} target='_blank'>X</Dropdown.Item>
                            <Dropdown.Item eventKey="5" onClick={copyLink}>Discord</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                </Col>
            </Row>
            </div>
            </>
        )}
        </>
    )
}

export default PreviewImage;