import React, { useEffect, useState } from 'react';

import { Modal, Button, Tabs, Tab, Form, Table, Badge, Pagination } from 'react-bootstrap';

import Select from 'react-select'
import makeAnimated from 'react-select/animated';

import LoadingSpinner from './LoadingSpinner';

import axios from 'axios';

const PanelFeed = () => {
    const password = localStorage.getItem('password')

    const [isLoading, setIsLoading] = useState(false)

    const [feeds, setFeeds] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const fetchAllFeeds = async (page) => {

        try {
            setIsLoading(true);

            const response = await fetch(`https://gorillamediallc.com/betabbserver/api/getallfeeds?page=${page}&limit=10`);
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const data = await response.json();
            setFeeds(data.feeds);
            setTotalPages(data.totalPages);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchAllFeeds(currentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        fetchAllFeeds()
        setShow(true);
    }

    const animatedComponents = makeAnimated();
    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#111",
          borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
          borderColor: state.isFocused ? "purple" : "purple",
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            borderColor: state.isFocused ? "purple" : "purple",
            background: "black",
            color: "white"
          }
        }),
        option: (base, state) => ({
            "&:hover": {
                borderColor: state.isFocused ? "purple" : "purple",
                background: "black",
                color: "white"
              }    
        }),
        menu: base => ({
          ...base,
          borderRadius: 0,
          marginTop: 0
        }),
        menuList: (base, state) => ({
          ...base,
          background: "purple",
          padding: "0.25rem",
        })
    };

    const [allCategories, setAllCategories] = useState([])
    const [allChannels, setAllChannels] = useState([])

    // ADD FEED
    const [file, setFile] = useState(null);
    const [feedDesc, setFeedDesc] = useState('')
    const [feedCats, setFeedCats] = useState([])
    const [feedChan, setFeedChan] = useState([])
    const [feedSource, setFeedSource] = useState('')

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleChangeCategories = (cats) => {
        setFeedCats(cats)
    }

    const handleChangeChannels = (chans) => {
        setFeedChan(chans)
    }
    
    const handleAddFeed = async (event) => {
            event.preventDefault();
    
            const formData = new FormData();
            formData.append('file', file);
            formData.append('description', feedDesc);
            formData.append('categories', JSON.stringify(feedCats));
            formData.append('channels', JSON.stringify(feedChan));
            formData.append('source', feedSource);

            try {
                setIsLoading(true)
                
                const response = await fetch('https://gorillamediallc.com/betabbserver/api/addfeed', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': 'Auth ' + password
                }
                });

                if(response.status === 200) {
                    resetForm()
                    handleClose()
                } 
    
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.error(error);
            }
    };

    const resetForm = () => {
        setFile(null);
        setFeedDesc('');
        setFeedCats([]);
        setFeedChan([]);
        setFeedSource('')
    };
 
    const [massFile, setMassFile] = useState(null);

    const handleFileChangeMass = (event) => {
        setMassFile(event.target.files[0]);
    };

    const handleMassFeed = async (event) => {
        event.preventDefault();
    
        const formData = new FormData();
        formData.append('file', massFile);
    
        try {
            const response = await axios.post('https://gorillamediallc.com/betabbserver/api/massfeed', formData, {
                headers: {
                    'Authorization': 'Auth ' + password,
                    'Content-Type': 'multipart/form-data', 
                },
            });
    
            const data = response.data; 
    
            if (data.message === 'success') {
                setMassFile(null);
                handleClose();
            }
    
            console.log(data);
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
        }
    };
    
    // Channels
    const [channels, setChannels] = useState([])
    
    const [channelName, setChannelName] = useState('')

    const handleAddChannels = async (e) => {
        e.preventDefault();
    
        try {
            const response = await fetch('https://gorillamediallc.com/betabbserver/api/addfeedchannel', {
                method: 'POST',
                body: JSON.stringify({ name: channelName }), 
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': 'Auth ' + password
                }
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
    
            const data = await response.json();
            console.log(data);
            if(data.message === "success" && data.channel) {
                setChannelName('')
                setChannels((prev) => [...prev, data.channel])
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleGetChannels = async () => {
        try {
            const response = await fetch(`https://gorillamediallc.com/betabbserver/api/getfeedchannels`, {
                method: 'GET'
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
    
            const data = await response.json();

            const formatChannels = data.channels.length > 0 && data.channels.map(c => ({
                label: c.name,
                value: c.name
            }));

            setChannels(data.channels);
            setAllChannels(formatChannels)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        handleGetChannels()
    }, []);

    const handleDeleteChannel = async (channelId) => {
        try {
            const response = await fetch(`https://gorillamediallc.com/betabbserver/api/deletefeedchannel/${channelId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Auth ' + password
                }
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
    
            const data = await response.json();
            if(data.message === 'success') {
                setChannels((prev) => prev.filter((item) => item._id !== channelId))
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const [editChannelId, setEditChannelId] = useState('')
    const [editChannelName, setEditChannelName] = useState('')

    const handleEditChannel = (chan) => {
        setEditChannelId(chan._id)
        setEditChannelName(chan.name)
    }

    const handleEditChannelFinish = async () => {
            try {
                const response = await fetch(`https://gorillamediallc.com/betabbserver/api/updatefeedchannel`, {
                    method: 'PATCH',
                    body: JSON.stringify({ 
                        id: editChannelId,
                        name: editChannelName
                     }), 
                    headers: {
                        'Content-Type': 'application/json', 
                        'Authorization': 'Auth ' + password
                    }
                });
        
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
        
                const data = await response.json();
                if(data.message === 'success' && data.channel) {
                    setEditChannelId('')
                    setEditChannelName('')

                    setChannels((prevChannels) =>
                        prevChannels.map((channel) =>
                            channel._id === data.channel._id ? { ...channel, name: data.channel.name } : channel
                        )
                    );
    
                }
            } catch (error) {
                console.error('Error:', error);
            }        
    }

    // Categories
    const [categories, setCategories] = useState([])

    const [categoryName, setCategoryName] = useState('')

    const handleAddCategories = async (e) => {
        e.preventDefault();
    
        try {
            const response = await fetch('https://gorillamediallc.com/betabbserver/api/addfeedcategory', {
                method: 'POST',
                body: JSON.stringify({ name: categoryName }), 
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': 'Auth ' + password
                }
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
    
            const data = await response.json();
            console.log(data);
            if(data.message === "success" && data.category) {
                setCategoryName('')
                setCategories((prev) => [...prev, data.category])
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    const handleGetCategories = async () => {
        try {
            const response = await fetch(`https://gorillamediallc.com/betabbserver/api/getfeedcategories`, {
                method: 'GET'
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
    
            const data = await response.json();

            const formatCats = data.categories.length > 0 && data.categories.map(c => ({
                label: c.name,
                value: c.name
            }));

            setCategories(data.categories);
            setAllCategories(formatCats)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        handleGetCategories()
    }, []);

    const handleDeleteCategory = async (categoryId) => {
        try {
            const response = await fetch(`https://gorillamediallc.com/betabbserver/api/deletefeedcategory/${categoryId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Auth ' + password
                }
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
    
            const data = await response.json();
            if(data.message === 'success') {
                setCategories((prev) => prev.filter((item) => item._id !== categoryId))
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const [editCategoryId, setEditCategoryId] = useState('')
    const [editCategoryName, setEditCategoryName] = useState('')

    const handleEditCategory = (cat) => {
        setEditCategoryId(cat._id)
        setEditCategoryName(cat.name)
    }

    const handleEditCategoryFinish = async () => {
            try {
                const response = await fetch(`https://gorillamediallc.com/betabbserver/api/updatefeedcategory`, {
                    method: 'PATCH',
                    body: JSON.stringify({ 
                        id: editCategoryId,
                        name: editCategoryName
                     }), 
                    headers: {
                        'Content-Type': 'application/json', 
                        'Authorization': 'Auth ' + password
                    }
                });
        
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
        
                const data = await response.json();
                if(data.message === 'success' && data.category) {
                    setEditCategoryId('')
                    setEditCategoryName('')

                    setCategories((prevCategories) =>
                        prevCategories.map((category) =>
                            category._id === data.category._id ? { ...category, name: data.category.name } : category
                        )
                    );
    
                }
            } catch (error) {
                console.error('Error:', error);
            }        
    }

    const handleDeleteFeed = async (feedId) => {
        try {
            const response = await fetch(`https://gorillamediallc.com/betabbserver/api/deletefeed/${feedId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Auth ' + password
                }
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
    
            const data = await response.json();
            if(data.message === 'success') {
                setFeeds((prev) => prev.filter((item) => item._id !== feedId))
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Images FEED
    const [fileImage, setFileImage] = useState(null);
    const [imageDesc, setImageDesc] = useState('')
    const [imageCats, setImageCats] = useState([])
    const [imageChan, setImageChan] = useState([])
    const [imageSource, setImageSource] = useState('')

    const handleFileChangeImage = (event) => {
        setFileImage(event.target.files[0]);
    };

    const handleChangeCategoriesImage = (cats) => {
        setImageCats(cats)
    }

    const handleChangeChannelsImage = (chans) => {
        setImageChan(chans)
    }
    
    const handleAddImage = async (event) => {
            event.preventDefault();
    
            const formData = new FormData();
            formData.append('file', fileImage);
            formData.append('description', imageDesc);
            formData.append('categories', JSON.stringify(imageCats));
            formData.append('channels', JSON.stringify(imageChan));
            formData.append('source', imageSource);

            try {
                setIsLoading(true)
                
                const response = await fetch('https://gorillamediallc.com/betabbserver/api/addimage', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': 'Auth ' + password
                }
                });

                if(response.status === 200) {
                    resetFormImage()
                    handleClose()
                } 
    
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.error(error);
            }
    };

    const resetFormImage = () => {
        setFileImage(null);
        setImageDesc('');
        setImageCats([]);
        setImageChan([]);
        setImageSource('')
    };

    const [massFileImage, setMassFileImage] = useState(null);

    const handleFileChangeMassImages = (event) => {
        setMassFileImage(event.target.files[0]);
    };

    const handleMassImages = async (event) => {
        event.preventDefault();
    
        const formData = new FormData();
        formData.append('file', massFileImage);
    
        try {
            const response = await axios.post('https://gorillamediallc.com/betabbserver/api/massimages', formData, {
                headers: {
                    'Authorization': 'Auth ' + password,
                    'Content-Type': 'multipart/form-data', 
                },
            });
    
            const data = response.data; 
    
            if (data.message === 'success') {
                setMassFileImage(null);
                handleClose();
            }
    
            console.log(data);
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
        }
    };


    return (
        <>
        <Button variant="warning" onClick={handleShow}>
            Feed Settings
        </Button>

        <Modal show={show} onHide={handleClose} className="custom_adsmodal" size='lg'>
            <Modal.Header closeButton>
            <Modal.Title>Feed Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body className='position-relative'>
            
            {isLoading && <LoadingSpinner asOverlay={true} />}

            <Tabs
            defaultActiveKey="list"
            id="uncontrolled-tab-example"
            className="mb-3"
            >
            <Tab eventKey='list' title='List'>
                {feeds && feeds.length < 1 ? (
                    <>
                    <p>No results!</p>
                    </>
                ) : (
                    <>
                    <Table responsive className="stats-table-custom video-list-table">
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Preview</th>
                        <th>Categories</th>
                        <th>Channels</th>
                        <th></th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {feeds.map((f, index) => {
                        return (
                            <React.Fragment key={`f-${index}`}>
                            <tr>
                            <td>{index + 1}.</td>
                            <td>
                                {f.type === 'video' ? (
                                    <>
                                    <video width="200" height="100" controls>
                                    <source src={`https://gorillamediallc.com/betabbserver/${f.video}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                    </video>
                                    </>
                                ) : (
                                    <>
                                    <div className='image-feed-p'>
                                    <img src={`https://gorillamediallc.com/betabbserver/${f.video}`} className='img-fluid' />
                                    </div>
                                    </>
                                )}
                            </td>
                            <td>
                            {f.categories.map((fcat, index) => {
                                return (
                                    <React.Fragment key={`${f.video}-${fcat.label}-${index}`}>
                                        <Badge bg='dark' className='m-1'>{fcat.label}</Badge>
                                    </React.Fragment>
                                )
                            })}
                            </td>
                            <td>
                            {f.channels.map((fchan, index) => {
                                return (
                                    <React.Fragment key={`${f.video}-${fchan.label}-${index}`}>
                                        <Badge bg='dark' className='m-1'>{fchan.label}</Badge>
                                    </React.Fragment>
                                )
                            })}
                            </td>
                            <td>
                            <a href={`https://gorillamediallc.com/betabbserver/${f.video}`} target="_blank" rel="noopener noreferrer">
                                <Button variant="warning" size="sm"><i className="fa-solid fa-arrow-up-right-from-square"></i></Button>
                            </a>
                            </td>
                            <td>
                                <Button variant='danger' size='sm' onClick={() => handleDeleteFeed(f._id)}><i className="fa-solid fa-trash"></i></Button>
                            </td>
                            </tr>
                            </React.Fragment>
                        )
                    })}
                    </tbody>
                    </Table>
                    </>
                )}


                <div className="feed-pag">
                <Pagination>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Pagination.Item
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            active={currentPage === index + 1}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
                </div>
            </Tab>
            <Tab eventKey="add" title="Add Feed">
                <Form onSubmit={handleAddFeed}>
                    <Form.Group className="mb-3" controlId="Form.ControlInputFile">
                        <Form.Label>File:</Form.Label>
                        <Form.Control 
                            type="file" 
                            accept="video/mp4" 
                            onChange={handleFileChange} 
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Form.ControlInputDesc">
                        <Form.Label>Description:</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            value={feedDesc} 
                            onChange={(e) => setFeedDesc(e.target.value)} 
                            rows={3} 
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Form.ControlInputCats">
                        <Form.Label>Categories:</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isMulti
                            options={allCategories}
                            onChange={handleChangeCategories}
                            className="custom-reactselect"
                            styles={customStyles}
                            value={feedCats}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Form.ControlInputChans">
                        <Form.Label>Channels:</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isMulti
                            options={allChannels}
                            onChange={handleChangeChannels}
                            className="custom-reactselect"
                            styles={customStyles}
                            value={feedChan}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Form.ControlInputSource">
                        <Form.Label>Source:</Form.Label>
                        <Form.Control type="text" value={feedSource} onChange={(e) => setFeedSource(e.target.value)} />
                    </Form.Group>
                    <Button variant="success" type="submit">
                        Save
                    </Button>
                </Form>
            </Tab>
            <Tab eventKey="upload" title="Upload Feeds">
                        <Form onSubmit={handleMassFeed}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCSV">
                                <Form.Label>File:</Form.Label>
                                <Form.Control type="file" accept=".csv" onChange={handleFileChangeMass} />
                            </Form.Group>
                            <Button variant="success" type="submit">
                                Save
                            </Button>
                        </Form>
            </Tab>
            <Tab eventKey="add-image" title="Add Image">
                <Form onSubmit={handleAddImage}>
                    <Form.Group className="mb-3" controlId="Form.ControlInputFileImage">
                        <Form.Label>Image:</Form.Label>
                        <Form.Control 
                            type="file" 
                            accept="image/*" 
                            onChange={handleFileChangeImage} 
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Form.ControlInputDescImage">
                        <Form.Label>Description:</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            value={imageDesc} 
                            onChange={(e) => setImageDesc(e.target.value)} 
                            rows={3} 
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Form.ControlInputCatsImage">
                        <Form.Label>Categories:</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isMulti
                            options={allCategories}
                            onChange={handleChangeCategoriesImage}
                            className="custom-reactselect"
                            styles={customStyles}
                            value={imageCats}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Form.ControlInputChansImage">
                        <Form.Label>Channels:</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isMulti
                            options={allChannels}
                            onChange={handleChangeChannelsImage}
                            className="custom-reactselect"
                            styles={customStyles}
                            value={imageChan}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Form.ControlInputSourceImage">
                        <Form.Label>Source:</Form.Label>
                        <Form.Control type="text" value={imageSource} onChange={(e) => setImageSource(e.target.value)} />
                    </Form.Group>
                    <Button variant="success" type="submit">
                        Save
                    </Button>
                </Form>
            </Tab>
            <Tab eventKey="upload-images" title="Upload Images">
                        <Form onSubmit={handleMassImages}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCSVImages">
                                <Form.Label>File:</Form.Label>
                                <Form.Control type="file" accept=".csv" onChange={handleFileChangeMassImages} />
                            </Form.Group>
                            <Button variant="success" type="submit">
                                Save
                            </Button>
                        </Form>
            </Tab>
            <Tab eventKey="categories" title="Categories">
                        <Form onSubmit={handleAddCategories}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputCatName">
                                <Form.Label>Category name:</Form.Label>
                                <Form.Control type="text" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
                            </Form.Group>
                            <Button variant="success" type="submit">
                                Add
                            </Button>
                        </Form>

                        <hr />

                        {categories && categories.length === 0 ? (
                            <>
                            <p>No categories</p>
                            </>
                        ) : (
                            <>
                                <Table responsive className="stats-table-custom">
                                    <thead>
                                        <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categories.map((cat, index) => {
                                            return (
                                                <React.Fragment key={`cat-${index}`}>
                                                <tr>   
                                                    {editCategoryId === cat._id ? (
                                                        <>
                                                        <td>{index + 1}.</td>
                                                        <td className='tab-buttons-custom' style={{ color: 'white' }}>
                                                            <Form.Control type="text" value={editCategoryName} onChange={(e) => setEditCategoryName(e.target.value)} />
                                                        </td>
                                                        <td className='tab-buttons-custom'>
                                                            <Button variant='success' size='sm' onClick={() => handleEditCategoryFinish()}><i className="fa-solid fa-check"></i></Button>
                                                            <Button variant='danger' size='sm' onClick={() => {
                                                                setEditCategoryId('')
                                                                setEditCategoryName('')
                                                            }} className='mx-2'><i className="fa-solid fa-minus"></i></Button>

                                                        </td>
                                                        </>
                                                    ) : (
                                                        <>
                                                        <td>{index + 1}.</td>
                                                        <td style={{ color: 'white' }}>{cat.name}</td>
                                                        <td className='tab-buttons-custom'>
                                                            <Button variant='danger' size='sm' onClick={() => handleDeleteCategory(cat._id)}><i className="fa-solid fa-trash"></i></Button>
                                                            <Button variant='warning' size='sm' onClick={() => handleEditCategory(cat)} className='mx-2'><i className="fa-solid fa-pen-to-square"></i></Button>
                                                        </td>
                                                        </>
                                                    )}
                                                </tr>
                                                </React.Fragment>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </>
                        )}

            </Tab>
            <Tab eventKey="channels" title="Channels">
                        <Form onSubmit={handleAddChannels}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInputChanName">
                                <Form.Label>Channel name:</Form.Label>
                                <Form.Control type="text" value={channelName} onChange={(e) => setChannelName(e.target.value)} />
                            </Form.Group>
                            <Button variant="success" type="submit">
                                Add
                            </Button>
                        </Form>

                        <hr />

                        {channels && channels.length === 0 ? (
                            <>
                            <p>No channels</p>
                            </>
                        ) : (
                            <>
                                <Table responsive className="stats-table-custom">
                                    <thead>
                                        <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {channels.map((chan, index) => {
                                            return (
                                                <React.Fragment key={`chan-${index}`}>
                                                <tr>   
                                                    {editChannelId === chan._id ? (
                                                        <>
                                                        <td>{index + 1}.</td>
                                                        <td className='tab-buttons-custom' style={{ color: 'white' }}>
                                                            <Form.Control type="text" value={editChannelName} onChange={(e) => setEditChannelName(e.target.value)} />
                                                        </td>
                                                        <td className='tab-buttons-custom'>
                                                            <Button variant='success' size='sm' onClick={() => handleEditChannelFinish()}><i className="fa-solid fa-check"></i></Button>
                                                            <Button variant='danger' size='sm' onClick={() => {
                                                                setEditChannelId('')
                                                                setEditChannelName('')
                                                            }} className='mx-2'><i className="fa-solid fa-minus"></i></Button>

                                                        </td>
                                                        </>
                                                    ) : (
                                                        <>
                                                        <td>{index + 1}.</td>
                                                        <td style={{ color: 'white' }}>{chan.name}</td>
                                                        <td className='tab-buttons-custom'>
                                                            <Button variant='danger' size='sm' onClick={() => handleDeleteChannel(chan._id)}><i className="fa-solid fa-trash"></i></Button>
                                                            <Button variant='warning' size='sm' onClick={() => handleEditChannel(chan)} className='mx-2'><i className="fa-solid fa-pen-to-square"></i></Button>
                                                        </td>
                                                        </>
                                                    )}
                                                </tr>
                                                </React.Fragment>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </>
                        )}

            </Tab>
            </Tabs>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default PanelFeed;