import { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from './http-hook';

export const useAuth = () => {
  const { sendRequest } = useHttpClient();

  const [email, setEmail] = useState(false);
  const [username, setUsername] = useState();

  const duplicateData = JSON.parse(localStorage.getItem('user')) || null

  const logout = useCallback(() => {
    setEmail(null);
    setUsername(null);
    localStorage.removeItem('user');
    localStorage.removeItem('liked_list');
    localStorage.removeItem('messages_list');
    localStorage.removeItem('disliked_list');
    localStorage.removeItem('visitors_list');
    localStorage.removeItem('matches_list');
    localStorage.removeItem('honplayed');

    window.location.href = '/'
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('user'));
    if(storedData){
        setEmail(storedData.email)
        setUsername(storedData.username)
    }
  }, []);

  // // visitors
  // const scriptVisitors = async () => {

  //   if(localStorage.getItem('honplayed') === "true") {
  //     const response = await sendRequest('../data/users.json');
  //     const result = response.sort(function() { 
  //         return .5 - Math.random() 
  //     }).slice(0, 1);

  //     const userObject = {
  //       image: result[0].image,
  //       name: result[0].name,
  //       nick: result[0].nick
  //     }
      
  //     const body = {
  //       member: duplicateData.username,
  //       content: userObject,
  //       type: "visitors"
  //     }

  //     try {
  //       await sendRequest(
  //         'https://gorillamediallc.com/betabbserver/api/usersitems',
  //         'POST',
  //         JSON.stringify(body),
  //         {
  //           'Content-Type': 'application/json'
  //         }
  //       );  
  //     } catch (err) {
  //       console.log(err)
  //     }

  //   }

  // }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //       scriptVisitors()
  //   }, 60000);
  //   return () => clearInterval(interval);
  // }, []);

  // // messages
  // const scriptMessages = async () => {

  //   if(localStorage.getItem('honplayed') === "true") {
  //     const response = await sendRequest('../data/users.json');
  //     const result = response.sort(function() { 
  //         return .5 - Math.random() 
  //     }).slice(0, 1);

  //     const responseMessages = await sendRequest('https://gorillamediallc.com/betabbserver/stored/data/messages_list.json');
  //     const resultMessages = responseMessages.sort(function() {
  //       return .5 - Math.random()
  //     }).slice(0, 1);
      
  //     const random = Math.floor(Math.random() * resultMessages[0].messages.length);
  //     const random_message = resultMessages[0].messages[random];

  //     const body = {
  //       member: duplicateData?.username,
  //       content: {
  //         nick: result[0]?.nick,
  //         image: result[0]?.image,
  //         redirect: resultMessages[0]?.redirect,
  //         message: random_message?.toString()
  //       },
  //       type: "messages"
  //     }

  //     try {
  //       await sendRequest(
  //         'https://gorillamediallc.com/betabbserver/api/usersitems',
  //         'POST',
  //         JSON.stringify(body),
  //         {
  //           'Content-Type': 'application/json'
  //         }
  //       );  
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   }

  // }

  // useEffect(async () => {
  //   const response = await sendRequest('https://gorillamediallc.com/betabbserver/api/getusersitems')
  //   const filter = response.user_items.filter((item) => item.type === "messages" && item.member === duplicateData?.username)

  //   const interval = setInterval(() => {
  //     if(filter.length < 5) scriptMessages()
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //       scriptMessages()
  //   }, 120000);

  //   return () => clearInterval(interval);
  // }, []);


  // // matches
  // const scriptMatches = async () => {

  //   if(localStorage.getItem('honplayed') === "true") {
  //     const response = await sendRequest('../data/users.json');
  //     const result = response.sort(function() { 
  //         return .5 - Math.random() 
  //     }).slice(0, 1);

  //     const userObject = {
  //       image: result[0].image,
  //       name: result[0].name,
  //       nick: result[0].nick
  //     }

  //     const body = {
  //       member: duplicateData.username,
  //       content: userObject,
  //       type: "matches"
  //     }
      
  //     try {
  //       await sendRequest(
  //         'https://gorillamediallc.com/betabbserver/api/usersitems',
  //         'POST',
  //         JSON.stringify(body),
  //         {
  //           'Content-Type': 'application/json'
  //         }
  //       );  
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   }

  // }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     scriptMatches()
  //   }, 180000);
  //   return () => clearInterval(interval);
  // }, []);

  // // status
  // const scriptStatus = async () => {
  //   const response = await sendRequest('../data/users.json');
  //   const result = response.sort(function() { 
  //       return .5 - Math.random() 
  //   }).slice(0, 1);

  //   const image = result[0].images?.sort(function() { 
  //     return .5 - Math.random() 
  //   }).slice(0, 1)

  //   try {
  //     await sendRequest(
  //       'https://gorillamediallc.com/betabbserver/api/poststatus',
  //       'POST',
  //       JSON.stringify({
  //         id: Math.floor(Math.random() * 999999) + 1,
  //         author: result[0].nick,
  //         status: '',
  //         file: image[0].src,
  //         date: Date.now(),
  //         type: 1,
  //         comments: [],
  //         likes: []
  //       }),
  //       {
  //         'Content-Type': 'application/json'
  //       }
  //     );
  //   } catch (err) {
  //     console.log(err)
  //   }

  // }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     scriptStatus()
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, []);


  return { email, username, logout };
};